import React, { Component ,Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import MediaQuery from 'react-responsive';
import $ from 'jquery'
import Landing from './components/Landing/landing';
import Navbar from './components/navbar/navbar';
import Card from './components/cards/card';
import Contacto from './components/contacto/contacto';
import Footer from './components/footer/footer';
import Loading from './components/loading/loading';
import Inicio from './pages/inicio/inicio';
import PageModel from './pages/pageModel/pageModel';
import AboutUs from './pages/nosotros/nosotros';
import AtencionAlCliente from './pages/AtencionAlCliente/atencionAlCliente';
import Inversionistas from './pages/inversionistas/inversionistas';
import VendeTuTerreno from './pages/vendeTuTerreno/vendeTuTerreno';
import global from './properties/properties.js'

export default class Routes extends Component {
    render() {
        return (
            <div className="Main-pages">
                    <Router>
                        <Loading/>
                        <Navbar></Navbar>
                        <Switch> 
                            <Route exact path="/">
                                <Inicio/>
                            </Route>
                            <Route exact path="/skyna">
                                <PageModel properties = {global.skyna}/>
                            </Route>
                            <Route exact path="/Garden">
                                <PageModel properties = {global.garden}/>
                            </Route>
                            <Route exact path="/Avantte">
                                <PageModel properties = {global.avantte}/>
                            </Route>
                            <Route exact path="/nosotros">
                                <AboutUs properties = {global.aboutUsInfo}/>
                            </Route>
                            <Route exact path="/atencion-al-cliente">
                                <AtencionAlCliente/>
                            </Route>
                            <Route exact path="/vende-tu-terreno">
                                <VendeTuTerreno/>
                            </Route>
                            <Route exact path="/inversionistas">
                                <Inversionistas/>
                            </Route>
                           
                        </Switch>
                        <Footer></Footer>
                    </Router>
            </div>
        )
    }
}
