import React, { Component } from 'react'
import './contactModel.scss'
import { FormContainer, checkableBoolProps, setInputProps } from '../../components/common/forms/Form';
import { BaseValidatonSchema } from '../../components/common/forms/constraints/ValidatonSchemas';
import Swal from 'sweetalert2';
import { SperantService } from '../common/Http/api';

export default function ContactModel(props) {
    const submitForm = (values, { setSubmitting, resetForm }) => {
        const sperant = new SperantService()
        sperant.createClient({
            fname: values.fname,
            lname: values.lname,
            main_telephone: values.telephone,
            email: values.email,
            project_related: props.project.id,
            interest_type_id: 5,
            input_channel_ids: 3,
            source_id: 8,
            observation: [
                values.rooms_amount && `Dorms: ${values.rooms_amount}`
            ].filter(Boolean).join(", ")
        }).then(_=>{
            setSubmitting(false)
            Swal.fire({
                title: '',
                icon: 'success',
                text: 'Datos enviados',
                confirmButtonText: 'Cerrar',
            })
        }).catch(_=>{
            Swal.fire({
                title: '',
                icon: 'error',
                text: 'Error',
                confirmButtonText: 'Cerrar',
            })
        })
    }
    const initialValues = {
        rooms_amount:"",
        fname: "",
        lname: "",
        telephone: "",
        email: "",
        terms: false,
    }
    return (
        <FormContainer initialValues={initialValues} validationSchema={BaseValidatonSchema} onSubmit={submitForm}>
            {form => {const {handleSubmit, isSubmitting} = form
            console.log(form.errors)
            return(
                <form className="formulario-model container mx-auto" id="formulario-de-contacto" onSubmit={handleSubmit}>
                <span className="d-block bold title-contactModel">Contáctanos</span>
                <div className="content-title">
                    <span className="title">¿En cuántos dormitorios está interesado?</span>
                    <div className="content-select d-flex">
                        {/* <input name="dorm" type="radio" id="option-1" {...setInputProps("rooms_amount", "option-dorm", form)}></input> */}
                        <input name="dorm" type="radio" id="option-2" {...setInputProps("rooms_amount", "option-dorm", form)} value="2"></input>
                        <input name="dorm" type="radio" id="option-3" {...setInputProps("rooms_amount", "option-dorm", form)} value="2"></input>
                        {/* <label for="option-1" className="button-select">01</label> */}
                        <label for="option-2" className="button-select">02</label>
                        <label for="option-3" className="button-select">03</label>
                    </div>
                </div>
                <div className="content-inputs">
                    <input placeholder="Nombres" {...setInputProps("fname", "input", form)}></input>
                    <input placeholder="Apellidos" {...setInputProps("lname", "input", form)}></input>
                    <input placeholder="Correo electrónico" {...setInputProps("email", "input", form)}></input>
                    <input placeholder="Teléfono / celular" {...setInputProps("telephone", "input", form)}></input>
                </div>
                <div className="terminos">
                    <input type="checkbox" name="term" id="term" {...checkableBoolProps("terms", "", form)}></input>
                    <label for="term" className="change"><div class="checked"></div></label>
                    <span className="">Acepto los <a target="_blank" href="#" className="term-and-condition">términos y condiciones</a></span>
                </div>
                <button type="submit" disabled={isSubmitting}>{isSubmitting ? "Enviando" : "Enviar"}</button>
            </form>
            )}}
        </FormContainer>
    )
}
