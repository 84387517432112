import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./Assets/scss/index.scss";
import Routes from "./routes";
import $ from "jquery";

window.$ = $
window.jQuery = window.$

ReactDOM.render(<Routes/>, document.getElementById('root'));
    
serviceWorker.unregister();
  