import React, { Component } from 'react'
import { InvestorsSchema } from '../../components/common/forms/constraints/ValidatonSchemas'
import { FormContainer, setInputProps } from '../../components/common/forms/Form'

export default class Inversionistas extends Component {
    componentDidMount(){
        document.querySelector("html").scrollTop = 0
        document.querySelector(".Navbar").classList.remove("inactiveDesktop")
        document.querySelector(".Navbar").classList.add("inactive")
        let links = document.querySelectorAll(".proyectos .project")
        links.forEach((e)=>{
            e.classList.remove("active")
        })
        document.querySelector("#link-inversionistas").classList.add("active")
    }
    handleSubmit = () => {
        alert()
    }
    initialValues = {
        names: "",
        email: "",
        telephone: "",
        message: "",
        terms: false
    }
    render() {
        return (
            <main className="Main-contacto inversionistas">
                <section className="section-header">
                    <span className="title-1 white">INVERSIONISTAS</span>
                    <h1 className="title title-2 green bold">¿POR QUÉ INVERTIR?</h1>
                    <span className="paragraph white">
                        Somos una empresa confiable, con altos
                        estándares de calidad. Tenemos proyectos en las
                        mejores zonas de Lima, entregando plusvalía y
                        proyección, que nos asegura una rentabilidad,
                        por sobre el mercado.
                    </span>
                </section>
                <FormContainer initialValues={this.initialValues} onSubmit={this.handleSubmit} validationSchema={InvestorsSchema}>
                    {form => {const { handleSubmit} = form;
                    console.log(form)    
                    return(
                        <form className="formulario" onSubmit={handleSubmit}>
                            <h2 className="title-form bold">Contáctanos</h2>
                            <div className="input"><input placeholder="Nombre y apellidos*" {...setInputProps("names", "", form)}></input></div>
                            <div className="input"><input placeholder="Correo electrónico*"{...setInputProps("email", "", form)}></input></div>
                            <div className="input"><input placeholder="Teléfono / Celular*" {...setInputProps("telephone", "", form)}></input></div>
                            <div className="input">
                                <textarea placeholder="Mensaje*" {...setInputProps("message", "", form)}></textarea>
                            </div>
                            <div className="terminos">
                                <input type="checkbox" name="term" id="term" {...setInputProps("terms", "", form)}></input>
                                <label for="term" className="change"><div className="checked"></div></label>
                                <span className="paragraph-term">Acepto los <a className="bold">términos y condiciones</a></span>
                            </div>
                            <button>
                                Enviar
                            </button>
                        </form>
                    )
                    }}
                </FormContainer>
            </main>
        )
    }
}
