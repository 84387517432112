import React, { Component } from 'react'
import './loading.scss'

export default class Loading extends Component {
    render() {
        const biabo = '';
        return (
            <div className="loading">
                <div className="biabo">
                    <img src={require("../../Assets/images/iconos/biabo-inicio.png")}></img>
                    <div className="animacion">

                    </div>
                </div>
            </div>
        )
    }
}
