import axios from "axios";

export class SperantService {
    constructor() {
        this.api = axios.create({
            baseURL: "https://api.sperant.com/v2/",
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": "Bearer R0PpTse4SK4AYGpSwBRJUqgEsnIsdfQJLJMQGl8pGjk"
            }
        })
    }

    getProjectId(name) {
        return projects[name]
    }

    async createClient(client) {
        new URLSearchParams(localStorage.getItem("url_query")).forEach((v, k) => {
            /utm_/.test(k) && (client[`${k.toLowerCase()}`] = v)
        })
        return await this.api.post("/clients", {data: client})
    }

    async createBudget(budget) {
        return await this.api.post("/budgets", {data: budget})
    }
}

const projects = {
    nou: 16,
    salaverry: 7,
    trend: 18,
    town: 17,
}