import React, { Component } from 'react'
import "./inicio.scss"
import OwlCarousel from "react-owl-carousel2";
import MediaQuery from 'react-responsive';
import $ from 'jquery'

const options = {
    items: 1,
    loop:true,
    smartSpeed:1000,
    mouseDrag: true,
    touchDrag: true,
    responsive:{
        
    },
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 54.55"><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path d="M.62,28.77,25.85,53.93a2.11,2.11,0,1,0,3-3L5.11,27.28,28.84,3.61A2.12,2.12,0,0,0,27.35,0a2.15,2.15,0,0,0-1.5.62L.62,25.78a2.11,2.11,0,0,0,0,3Z" style="fill:#606060"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 54.55"><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path d="M28.84,25.78,3.61.62a2.11,2.11,0,0,0-3,3L24.35,27.28.62,50.94a2.11,2.11,0,1,0,3,3L28.84,28.77a2.08,2.08,0,0,0,.62-1.49A2.1,2.1,0,0,0,28.84,25.78Z" style="fill:#fff"/></g></g></svg>'
    ],
    // animateOut: 'fadeOut',
    // animateIn: 'fadeIn',
    // pullDrag: true,

};
const events = {
    onTraslade:function(traslate){
    },
    onChanged:function(event){
        let num = event.page.index;
        $('.item-model').removeClass("event")
        $(`#render-${num < 0 ? num = 0 : num}`).addClass("event")
        console.log("this:",num)
    }}

    export default class SliderMainModel extends Component {
        constructor(props){
            super(props)
            this.state = {
                sliders:[1,2,3]
            }
        }
        componentDidMount(){
                console.log("this slider:",this.props.slider)
                document.getElementById("render-0").addEventListener("click",()=>{
                        $('#carousel-1').trigger('to.owl.carousel',0)
                });
                document.getElementById("render-1").addEventListener("click",()=>{
                        $('#carousel-1').trigger('to.owl.carousel',1)
                });
                document.getElementById("render-2").addEventListener("click",()=>{
                        $('#carousel-1').trigger('to.owl.carousel',2)
                });
            


            let rotateSlider;
            // function initRotate(){
            //     rotateSlider = setInterval(() => {
            //         $('#carousel-1').trigger('next.owl.carousel',1000)
            //     }, 7000);
            // }
            //  initRotate();   
            // $(".item-model").click(function(){
            //     clearInterval(rotateSlider);
            //     initRotate();
            // })
            // $(".link").removeClass("active")
            // $("#menu-inicio").addClass("active");
        }
        moveTo(){
            $('html, body').animate({
                scrollTop: $("#formulario-de-contacto").offset().top - 160
            }, 800);
        }
        render(){
        return (
            <div className="Slider-main-model">
                <div className="Slider-diap">
                    <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag" id="carousel-1">
                        {
                            this.state.sliders.map((e)=>{
                                return(

                                    <div className="Slider-main">
                                    <div className="info-content">
                                        <div className="info">
                                            <div className="brand desktop">
                                                <img src={this.props.slider[0].brand}></img>
                                            </div>
                                            <div className="address">
                                                <span className="title-1">{this.props.slider[0].title1}</span>
                                                <span className="title-2 bold">{this.props.slider[0].title2}</span>
                                                <span className="title-3" dangerouslySetInnerHTML={{__html:this.props.slider[0].title3}}></span>
                                            </div>
                                            <div className="cotizar" onClick={this.moveTo.bind(this)}>
                                                Cotizar
                                                <div class="signal"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-render">
                                        <img src={this.props.slider[0].desktop}></img>
                                    </div>
                                    <div className="brand movil">
                                        <img src={this.props.slider[0].brand}></img>
                                    </div>
                                </div>
                                )
                            })
                        }
                        
                    </OwlCarousel>
                </div>
                <div className="leyenda">
                    <div className="item-model event" id="render-0">
                        <div className="contador">
                            <div className="barra active"></div>
                        </div>
                        {/* <a  className="item-title"><p className="main-title">Nou</p> Pueblo Libre</a> */}
                    </div>
                    <div className="item-model" id="render-1">
                        <div className="contador">
                            <div className="barra active"></div>
                        </div>
                    </div>
                    <div className="item-model" id="render-2">
                        <div className="contador">
                            <div className="barra active"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
