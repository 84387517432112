import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel2";
import Slider from "react-slick";
import $ from 'jquery'
import SliderMainModel from '../../components/sliderMainModel/inicio'
import "./pageModel.scss";
import sr from  "./scrollReveal"
import SliderEligetuhogar from '../../components/sliderTipologias/sliderTipologias';
import ContactModel from '../../components/contactanosModel/contactModel';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery.js';
import 'lightgallery/dist/js/lightgallery-all.js';
import AreasGaleria from '../../components/AreasGaleria/AreasGaleria';
var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
 
mapboxgl.accessToken = 'pk.eyJ1IjoibWF0YXFxdWUiLCJhIjoiY2tldTEyc3k3Mm1hYTMzbzV6MjFudXFtYSJ9.qavxcQ4y4GdPsNuUaIeRdg';


const optionsPlace = {
    items: 2,
    // loop:true,
    smartSpeed:1000,
    mouseDrag: true,
    touchDrag: true,
    responsive:{
        700:{
            items:3,
        },
        900:{
            items:4,
        }
    },
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.63 21.57"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="11.71 20.65 1.84 10.79 11.71 0.92" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2.6"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.63 21.57"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="0.92 0.92 10.79 10.79 0.92 20.65" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2.6"/></g></g></svg>'
    ],
}
const options = {
    smartSpeed:1000,
    mouseDrag: true,
    touchDrag: true,
    review:true,
    nav:true,
    responsive:{
        0:{
            items:2,
            loop:false,
            autoWidth:true,
        },
        740:{
            items:3,
            loop:false,
            autoWidth:true,
        },
        940:{
            items:4,
            loop:false,
            autoWidth:true,
        }
    },
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 54.55"><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path d="M.62,28.77,25.85,53.93a2.11,2.11,0,1,0,3-3L5.11,27.28,28.84,3.61A2.12,2.12,0,0,0,27.35,0a2.15,2.15,0,0,0-1.5.62L.62,25.78a2.11,2.11,0,0,0,0,3Z" style="fill:#606060"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 54.55"><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path d="M28.84,25.78,3.61.62a2.11,2.11,0,0,0-3,3L24.35,27.28.62,50.94a2.11,2.11,0,1,0,3,3L28.84,28.77a2.08,2.08,0,0,0,.62-1.49A2.1,2.1,0,0,0,28.84,25.78Z" style="fill:#fff"/></g></g></svg>'
    ],
};
function event(el){
    el.classList.remove('is-animating');
    el.classList.add('is-visible');
}
const configModal = {
    selector: '.slide',
    thumbnail:true,
    animateThumb: false,
    showThumbByDefault: false,
    subHtmlSelectorRelative: true,
    rotate:false,
    actualSize:false,
    download:true,
    pinterest:false,
    googlePlus:false,
    twitter:false,
}
const bottomShow = {
    origin:"bottom",
    duration:1800,
    distance:"120px",
    delay:200,
    reset:false,
    easing:"ease-out"
}
const afterbotton = {
    origin:"bottom",
    duration:1800,
    distance:"120px",
    delay:300,
    reset:false,
    easing:"ease-out",
    afterReveal:event,
}
var site = "";
export default class PageModel extends Component {
    constructor(props){
        super(props)
        this.state = {
            proyecto:this.props.properties,
            titleSites:this.props.properties.sites[0].title
        }
        // this.first.push(this.interiorMiniatura)
    }
    componentWillUpdate (prevProps){
        
        document.querySelector("html").scrollTop = 0
        if(prevProps.properties !== this.props.properties) {
            this.setState({proyecto: this.props.properties});
          
            site = this.state.proyecto.ubicacion.img
            let x = this.state.proyecto.ubicacion.coord.x
            let y = this.state.proyecto.ubicacion.coord.y
            let size = this.state.proyecto.ubicacion.size

            var map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [x,y],
                zoom: 13.15
            });
            
            map.on('load', function () {
                map.loadImage(
                    site,
                function (error, image) {
                if (error) throw error;
                map.addImage('cat', image);
                map.addSource('point', {
                'type': 'geojson',
                'data': {
                'type': 'FeatureCollection',
                'features': [
                {
                'type': 'Feature',
                'geometry': {
                'type': 'Point',
                'coordinates': [x,y]
                }
                }
                ]
                }
                });
                map.addLayer({
                'id': 'points',
                'type': 'symbol',
                'source': 'point',
                'layout': {
                'icon-image': 'cat',
                'icon-size': size
                }
                });
                }
                );
                });
        }
    }
    componentDidMount(){
        let search = window.location.search;
        if(search == "?biabo"){
            if(document.querySelector(".loading")){
                document.querySelector(".loading").classList.add("none")
            }
        }

        $(".link").removeClass("active")
        $(`#${this.state.proyecto.title}`).addClass("active")
        site = this.state.proyecto.ubicacion.img
        let x = this.state.proyecto.ubicacion.coord.x
        let y = this.state.proyecto.ubicacion.coord.y
        let size = this.state.proyecto.ubicacion.size
        document.querySelector("html").scrollTop = 0;
        document.querySelector(".Navbar").classList.remove("inactive")
        sr.reveal(".flex-caracteristics",bottomShow );
        sr.reveal(".content-img", bottomShow);
        sr.reveal(".content-slider",bottomShow);
        sr.reveal(".multimedia .content-title",bottomShow);
        sr.reveal(".Slider-tipologias", bottomShow);
        sr.reveal(".content-place", bottomShow);
        sr.reveal(".place", bottomShow);
        sr.reveal(".formulario-model", bottomShow);
        sr.reveal(".overflow-img .title", afterbotton);

        $('.MyCarouselHorizontal').lightGallery(configModal);
        $('.slick-slider').lightGallery(configModal);

        document.querySelector(".Navbar").classList.add("inactiveDesktop")
        // document.querySelector(".Navbar").classList.add("inactive")
        var map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [x,y],
            zoom: 13.15
        });
        map.setStyle('mapbox://styles/mapbox/light-v10');
        
        map.on('load', function () {
            map.loadImage(
                site,
            function (error, image) {
            if (error) throw error;
            map.addImage('cat', image);
            map.addSource('point', {
            'type': 'geojson',
            'data': {
            'type': 'FeatureCollection',
            'features': [
            {
            'type': 'Feature',
            'geometry': {
            'type': 'Point',
            'coordinates': [x,y]
            }
            }
            ]
            }
            });
            map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'point',
            'layout': {
            'icon-image': 'cat',
            'icon-size': size
            }
            });
            }
            );
            });
   
    }
    
    
    changeToPlace(item,place,pos){
        $(".content-icon").removeClass("active")
        item.target.classList.add("active")
        $(".type").removeClass("active")
        $(`.${place}`).addClass("active")
        $(".title-place").text(this.props.properties.sites[pos].title)
        
    }
    changeSliderLeft(){
        var owl = $('.MyCarouselHorizontal');
        owl.trigger('prev.owl.carousel');
    }
    changeSliderRight(){
        var owl = $('.MyCarouselHorizontal');
        owl.trigger('next.owl.carousel');
        
    }
   
    render() {
    
    const events = {
        onTraslade:function(traslate){
        },
        onChanged:function(event){
            // $(document).ready(function(){
            //     let img = document.querySelector(`.multimedia .${area} .owl-item.active .slide .img-slide`).dataset.icon
            //     document.querySelector(".multimedia .icon-signal .icon").id = img
            //     let firstSlideCategory = document.querySelector(".slider-ubicacion .owl-item.active .slide-place").dataset.category
            //     $(".content-icon").removeClass("active")
            //     document.querySelector(`.${firstSlideCategory}`).classList.add("active")
            // })
        }}
    const settings = {
        dots: false,
        centerMode: false,
        speed: 100,
        variableWidth: false,
        infinite:false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 4,
              }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
              }
            },
            {
                breakpoint: 740,
                settings: {
                    slidesToShow: 2,
              }
            },
        ],
        beforeChange: function(current, next){
           
        },
    };
        
        return (

            <main className={"Page-model "+this.state.proyecto.title}>
                <SliderMainModel slider= {this.state.proyecto.sliderinicio}></SliderMainModel>
                <section className="section-caracteristics">
                <link href="https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css" rel="stylesheet" />
                    <div className="flex-caracteristics">
                        <span className="title-1">{this.state.proyecto.render.distrito}</span>
                        <span className="title-2"> {this.state.proyecto.render.address}</span>
                        <span className="title-3"> {this.state.proyecto.render.numero}</span>
                        <div className="content-caracteristics">
                            {
                                this.state.proyecto.render.features.map((e)=>{
                                    return(   
                                    <div className="caracteristic">
                                        <div className="item">
                                    <div className={"icon-item "+e.icon}></div><span className="title-icon">{e.title}</span>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="content-img">
                        <div className="overflow-img">
                            <img className="img-fluid" src={this.state.proyecto.render.img}></img>
                            <div className="title">{this.state.proyecto.render.avance}</div>
                        </div>
                    </div>
                </section>
                <AreasGaleria properties={this.props.properties}></AreasGaleria>
                <section className="section-tipologia container mx-auto">
                    <h2 className="d-flex text-center title movil">Elĳe tu Hogar</h2>
                    <SliderEligetuhogar tipologias={this.state.proyecto.tipologias}></SliderEligetuhogar>
                </section>
                <section className="place container mx-auto">
                    <div className="content-place">
                        <div className="content-icon --education active" onClick={(e)=>{this.changeToPlace(e,"--education",0)}}>
                            <div className="icon educacion"></div></div>
                        <div className="content-icon --colegios" onClick={(e)=>{this.changeToPlace(e,"--colegios",1)}}>
                            <div className="icon universidades"></div></div>
                        <div className="content-icon --charge" onClick={(e)=>{this.changeToPlace(e,"--charge",3)}}>
                            <div className="icon bateria"></div></div>
                        <div className="content-icon --restaurant" onClick={(e)=>{this.changeToPlace(e,"--restaurant",2)}}>
                            <div className="icon restaurantes"></div>
                        </div>
                    </div>
                    <div className="content-title">
                        <span className="title-place-1">
                            CERCA DE
                        </span>
                        <span className="title-place bold">{this.state.titleSites}</span>
                    </div>

                    <div className="slider-ubicacion">
                        {
                            this.state.proyecto.sites.map((item)=>{
                                return(
                                    <Slider {...settings} ref={c => (this.slider = c)} className={`Slider-characteristic ${item.site}`}>
                                    {
                                        item.map.map((item)=>{
                                            return(
                                                <div className="slide-place" data-category={item.category}>
                                                    <div className="content-title">
                                                        <span className="title bold">{item.title}</span>
                                                        <span className="sub-title ">{item.adress}</span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </Slider>
                                )
                            })
                        }
                        
                    </div>
                </section>
                <div id="map"></div>
                <section className="contactanosModel">
                    <ContactModel project={this.state.proyecto}></ContactModel>
                </section>
            </main>
        )
    }
}
