import * as Yup from 'yup';

export const BaseInterestedConstraints = {
    fname: Yup.string()
        .required(),
    lname: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
}
export const InvestorsSchema = values => Yup.object()
.shape({
    names: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
    message: Yup.string()
        .required(),
    terms:Yup.bool()
        .oneOf([true]).required(),
})

export const BaseValidatonSchema = values => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        terms: Yup.bool()
            .oneOf([true]).required(),
        rooms_amount: Yup.string()
        .required(),
    })

export const ContactUsValidationSchema = values => Yup.object()
    .shape({
        ...BaseInterestedConstraints,
        rooms_amount: Yup.string()
            .required(),
        project: Yup.number()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })
export const contactCenterSchema = values => Yup.object().
shape({
    names: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required(),
    telephone: Yup.string()
        .matches(/[\+?\-?0-9]{7,}/)
        .required(),
    project: Yup.string()
        .required(),
    apartament: Yup.string()
        .required(),
    file: Yup.string()
        .required(),
    message: Yup.string()
        .required(),
    terms: Yup.bool()
        .oneOf([true]).required()
})

export const LandSellerValidationSchema = values => Yup.object()
    .shape({
        names: Yup.string()
            .required(),
        email: Yup.string()
            .email()
            .required(),
        telephone: Yup.string()
            .matches(/[\+?\-?0-9]{7,}/)
            .required(),
        region: Yup.string()
            .required(),
        district: Yup.string()
            .required(),
        address: Yup.string()
            .required(),
        file: Yup.string()
            .required(),
        message: Yup.string()
            .required(),
        terms: Yup.bool()
            .oneOf([true]).required()
    })