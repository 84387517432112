import React, { Component } from 'react'
import OwlCarousel from "react-owl-carousel2";
import $ from 'jquery'
import SliderMainModel from '../../components/sliderMainModel/inicio'
import "./slidertipologias.scss"
const options = {
    items: 3,
    // loop:true,
    smartSpeed:400,
    mouseDrag: false,
    responsive:{
        0:{
            margin:20,
            items:3
        },
        700:{
            items:5
        },
        970:{
            items:4
        },
        1100:{
            items:6
        }
    },
    nav:true,
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.63 21.57"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="11.71 20.65 1.84 10.79 11.71 0.92" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2.6"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.63 21.57"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="0.92 0.92 10.79 10.79 0.92 20.65" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2.6"/></g></g></svg>'
    ],
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplay:false
};
const events = {
    onTraslade:function(traslate){
    },
    onChanged:function(event){

    }
}
export default class SliderEligetuhogar extends Component {
    constructor(props){
        super(props)
        this.state = {
            tipologia: this.props.tipologias[0],
            position: 0,
        }
  
    }
    componentDidMount(){
        // let items = document.querySelectorAll(".Slider-tipologias .owl-item.active")
        // let itemTipologia = document.querySelectorAll(".Slider-tipologias .owl-item.active")[items.length-1]
        // document.querySelector(".Slider-tipologias .owl-item:last-child").addEventListener("click",function(){
        //     document.querySelector(".Slider-tipologias .owl-next").click()
        // })
        let btnTipologia = document.querySelector(".third-flat")
        let textBtn = document.querySelector(".third-flat .text")
        document.querySelector(".tipologia-0").classList.add("active")
        if(document.querySelector(".tipologia-12")){
            document.querySelector(".tipologia-12").addEventListener("click",function(){
                btnTipologia.classList.add("active")
            });
            btnTipologia.addEventListener("click",function(){
                let img = document.querySelector(".img-main-tipologia .img")
                let imgContent = document.querySelector(".img-main-tipologia")
                if(btnTipologia.dataset.value == "true"){
                    img.src = "/img/tipologia13.png"
                    img.classList.remove("third")
                    imgContent.style = ''
                    textBtn.textContent = "Ver tercer piso"
                    btnTipologia.dataset.value = false
                }else{
                    img.src = "/img/tipologia13segundo.jpg";
                    img.classList.add("third")
                    imgContent.classList.add("content-third");
                    textBtn.textContent = "Ver segundo piso"
                    btnTipologia.dataset.value = true
                }
            })
        }

    }
    activeTipologia(item,index){
        let tipologia = item.target
        $(".tipologia").removeClass("active")
        tipologia.classList.add("active")
        this.setState({
            tipologia:this.props.tipologias[index]
        })
        if(document.querySelector(".third-flat") && index != 12){
            document.querySelector(".third-flat").classList.remove("active")
            document.querySelector(".third-flat").dataset.value = false;
            let img = document.querySelector(".img-main-tipologia .img")
            img.classList.remove("third")
        }
        // console.log("this item: ",item.target)
        // if(document.querySelector(".Slider-tipologias .owl-item:first-child") == item.target){
        //     alert("")
        //     document.querySelector(".Slider-tipologias .owl-prev").click()
        // }
        // if(document.querySelector(".Slider-tipologias .owl-item:last-child").className == "owl-item active"){
        //     document.querySelector(".Slider-tipologias .owl-next").click()
        // }
    }
    moveTo(){
        $('html, body').animate({
            scrollTop: $("#formulario-de-contacto").offset().top - 160
        }, 800);
    }
    render() {
        return (
            <div className="Slider-tipologias">
                <img className="img-none" src="/img/tipologia13.png"></img>
                <img className="img-none" src="/img/tipologia13segundo.jpg"></img>
                <h2 className="d-flex text-center title desktop">Elĳe tu Hogar</h2>
                <div className="content-slider">
                <OwlCarousel ref="car" options={options} events={events} className="owl-carousel icons-tipologogia owl-theme owl-loaded owl-drag" id="carousel-1">
                    {this.props.tipologias.map((item,index)=>{
                        return(
                        <div className={"tipologia "+"tipologia-"+index} onClick={((item)=>{this.activeTipologia(item,index)})}>
                            <div className="img-tipologia">
                                <img src={item.img}></img>
                            </div>
                            <div className="content-info">
                                <span className="tipo">{item.type}</span>
                                <span className="meters">{item.meters}</span>
                            </div>
                        </div>
                        )
                    })}
                </OwlCarousel>
                </div>
                <div className="tipologia-main">
                    <div className="img-main-tipologia">
                        <img className="img" src={this.state.tipologia.img}></img>
                        <span className="title-main-tipologia movil">{this.state.tipologia.type}</span>
                        <div className="third-flat">
                            <div className="text">Ver tercer piso </div>
                            <div className="signal"></div></div>
                    </div>
                    <div className="container-info-tipologia">
                        <div className="content-info-tipologia">
                            <div className="item area">
                                <span className="title-1">Área Total</span>
                                <span className="title-2 bold">{this.state.tipologia.meters}</span>
                            </div>
                            <div className="item map">
                                <img src={this.state.tipologia.sketch}></img>
                            </div>
                            <div className="item dorms">
                                <img src={require("../../Assets/images/iconos/svg/bed.svg")}></img>
                                <span className="title-item">{this.state.tipologia.dorms}</span>
                            </div>
                            <div className="item bath">
                                <img src={require("../../Assets/images/iconos/svg/bath.svg")}></img>
                                <span className="title-item">{this.state.tipologia.bath}</span>
                            </div>
                            <div className="cotizar" onClick={this.moveTo.bind(this)}>
                                <span className="bold">Cotizar</span>
                                <div className="signal"></div>
                            </div>
                        </div>
                        <span className="title-main-tipologia desktop">{this.state.tipologia.type}</span>
                    </div>
                </div>
            </div>
        )
    }
}
