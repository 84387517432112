import React, { Component } from 'react'
import Loading from '../../components/loading/loading'
import Card from '../../components/cards/card'
import Contacto from '../../components/contacto/contacto'
import Landing from '../../components/Landing/landing'

export default class Inicio extends Component {
    componentDidMount(){
        document.querySelector("html").scrollTop = 0
        document.querySelector(".Navbar").classList.remove("inactive")
        var e=["\n %c %c %c Created by Formula "+" - ✰ ✰ ✰ ✰ ✰ ","background: #000000; padding:5px 0;","background: #1d1d1d; padding:5px 0;","color: white; background: #030307; padding:5px 0;" ];
        window.console.log.apply(console,e)
        let links = document.querySelectorAll(".proyectos .project")
        links.forEach((e)=>{
            e.classList.remove("active")
        })
        document.querySelector("#link-inicio").classList.add("active")
        let search = window.location.search;
        let hash = window.location.hash
        if(search == "?biabo"){
            if(document.querySelector(".loading")){
                document.querySelector(".loading").classList.add("none")
            }
        }
        if(hash == "#proyectos" || hash == "#Contactanos"){
            if(document.querySelector(".loading")){
                document.querySelector(".loading").classList.add("none")
            }
        }
    }
    render() {
        return (
            <main>
                <Landing></Landing>
                <Card></Card>
                <Contacto></Contacto>
            </main>
        )
    }
}
